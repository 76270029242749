import { MDBListGroupItem, MDBIcon } from 'mdb-react-ui-kit';
import DayJS from 'dayjs';

const EstimateDetails = (props) => {
  let color = 'success';
  if (props.info.estimation == 2) {
    color = 'warning';
  } else if (props.info.estimation == 3) {
    color = 'danger';
  }
  let datum = DayJS(props.info.updatedAt);

  return (
    <>
      <MDBListGroupItem>
        <MDBIcon className='d-inline' icon='circle' color={color} />
        <div className='d-inline'> {datum.format('YYYY-MM-DD HH:mm')} </div>
        <div className='d-inline'>
          <b>{props.info.nickname}</b>
        </div>
        <div>{props.info.comment}</div>
      </MDBListGroupItem>
    </>
  );
};

export default EstimateDetails;
