import React from 'react';
import axios from 'axios';
import Estimate from '../lists/Estimate';
import { MDBCheckbox, MDBInput, MDBBtn } from 'mdb-react-ui-kit';

const UserEstimate = (props) => {
  const [estimation, setEstimation] = React.useState('1');
  const [reason, setReason] = React.useState('');
  const [estimates, setEstimates] = React.useState(props.estimates);

  const handleEstimate = (e) => {
    setEstimation(e.target.value);
  };

  const saveEstimate = () => {
    const token = localStorage.getItem('token');
    const url = process.env.REACT_APP_API_URL + 'infos/estimate/' + props.id;
    const body = { estimation: estimation, comment: reason };
    axios
      .post(url, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setEstimates(response.data.data);
        setReason('');
        setEstimation('1');
      });
  };

  return (
    <>
      <div>
        Wie schätzen Sie die Situation ein?
        <br />
        <br />
        Am {props.date} ist an diesen Parkplatz wahrscheinlich
      </div>
      <MDBCheckbox
        inline
        label='wenig los'
        type='radio'
        value='1'
        onChange={handleEstimate}
        checked={estimation === '1'}
      />
      <MDBCheckbox
        inline
        label='viel los'
        type='radio'
        value='2'
        onChange={handleEstimate}
        checked={estimation === '2'}
      />
      <MDBCheckbox
        inline
        label='kein Platz mehr frei'
        type='radio'
        value='3'
        onChange={handleEstimate}
        checked={estimation === '3'}
      />
      <MDBInput
        className='mb-4'
        type='text'
        label='Begründung'
        placeholder='Beispiel: wegen Veranstaltung sicher voll'
        onChange={(e) => setReason(e.target.value)}
        value={reason}
      />
      <MDBBtn onClick={() => saveEstimate()}>Speichern</MDBBtn>
      <hr />
      {estimates && estimates.length > 0 && <Estimate data={estimates} />}
      {!estimates ||
        (estimates.length === 0 && (
          <div>
            es wurden noch keine Einschätzungen für diesen Parklatz/Tag
            abgegeben.
          </div>
        ))}
    </>
  );
};

export default UserEstimate;
