import DayJS from 'dayjs';
import { MDBListGroupItem } from 'mdb-react-ui-kit';

const ChatDetails = (props) => {
  let datum = DayJS(props.info.updatedAt);

  return (
    <>
      <MDBListGroupItem>
        <div className='d-inline'> {datum.format('YYYY-MM-DD HH:mm')} </div>
        <div className='d-inline'>
          <b>{props.info.nickname}</b>
        </div>
        <div>{props.info.comment}</div>
      </MDBListGroupItem>
    </>
  );
};

export default ChatDetails;
