import React, { useState } from 'react';
import axios from 'axios';
import { MDBListGroup } from 'mdb-react-ui-kit';
import NextToursDetails from './NextToursDetails';

const NextTours = (props) => {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const url =
      process.env.REACT_APP_API_URL + 'infos/nexttours/' + props.idSpot;
    const token = localStorage.getItem('token');
    const config = {
      headers: {},
    };
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setData(response.data.data);
      });
  }, [props.idSpot]);

  const List = data.map((entry) => (
    <NextToursDetails key={entry.idTour} info={entry} datum={props.date} />
  ));

  return <>{data.length > 0 && <MDBListGroup>{List}</MDBListGroup>}</>;
};

export default NextTours;
