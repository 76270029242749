import React, { useState } from 'react';
import axios from 'axios';
import {
  MDBDatepicker,
  MDBInputGroup,
  MDBInputGroupElement,
  MDBBtn,
  MDBToast,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBCardFooter,
} from 'mdb-react-ui-kit';
import { useParams, useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';
import DayJS from 'dayjs';
import TourResult from '../lists/TourResult';
import Navbar from './Navbar';

const MainForm = () => {
  let navigate = useNavigate();
  let params = useParams();
  const tomorrow = DayJS().add(1, 'day');
  const today = DayJS();
  const [datepicker, setDatepicker] = useState(tomorrow.format('YYYY-MM-DD'));
  const [searchTerm, setSearchTerm] = React.useState('');

  // Fehlermeldung
  const [errMessage, setErrMessage] = useState('');
  // gefundene Touren
  const [data, setData] = useState(null);

  // Check, ob User angemeldet
  React.useEffect(() => {
    let isLoggedIn = false;
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      let decodedToken = jwt(token);
      let dateNow = DayJS();
      let dateExp = DayJS.unix(decodedToken.exp);
      if (dateExp.isBefore(dateNow)) {
        // Token abgelaufen
        localStorage.removeItem('token');
        navigate('/login');
      }
    }
  }, []);

  // Suche ausführen
  const search = () => {
    const url = process.env.REACT_APP_API_URL + 'infos/getlist';
    const token = localStorage.getItem('token');
    const config = {
      headers: {},
    };
    const bodyParameters = { searchField__contains: searchTerm };
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
        params: bodyParameters,
      })
      .then((response) => {
        setData(response.data);
      });
  };

  const searchSpot = () => {
    if (DayJS(datepicker, 'YYYY-MM-DD', true).isValid) {
      let d = DayJS(datepicker, 'YYYY-MM-DD', true);
      if (d.isBefore(today, 'day')) {
        setErrMessage('Ungültiges Datum');
      } else {
        search();
      }
    }
  };

  return (
    <>
      {errMessage.length > 0 && (
        <MDBToast
          color='danger'
          autohide
          position='top-right'
          delay={5000}
          show
          className='mx-auto'
          headerContent={
            <>
              <strong className='me-auto'>SpotMonitor</strong>
              <small>Fehlerhafte Eingabe</small>
            </>
          }
          bodyContent={errMessage}
        />
      )}
      <Navbar />
      <br />
      <MDBCard>
        <MDBCardBody>
          <MDBCardText>
            {' '}
            <MDBRow className='mb-3'>
              <MDBCol lg='3' className='mt-10'>
                <MDBDatepicker
                  title='Datum auswählen'
                  labelText='Datum'
                  format='yyyy-mm-dd'
                  min={today.add(-1, 'day').toDate()}
                  inputToggle
                  startDay={1}
                  monthsFull={[
                    'Januar',
                    'Februar',
                    'März',
                    'April',
                    'Mai',
                    'Juni',
                    'Juli',
                    'August',
                    'September',
                    'Oktober',
                    'November',
                    'Dezember',
                  ]}
                  monthsShort={[
                    'Jan',
                    'Feb',
                    'Mär',
                    'Apr',
                    'Mai',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Okt',
                    'Nov',
                    'Dez',
                  ]}
                  weekdaysFull={[
                    'Sonntag',
                    'Montag',
                    'Dienstag',
                    'Mittwoch',
                    'Donnerstag',
                    'Freitag',
                    'Samstag',
                  ]}
                  weekdaysShort={[
                    'Son',
                    'Mon',
                    'Die',
                    'Mit',
                    'Don',
                    'Fre',
                    'Sam',
                  ]}
                  weekdaysNarrow={['S', 'M', 'D', 'M', 'D', 'F', 'S']}
                  okBtnText='Ok'
                  clearBtnText=''
                  cancelBtnText='Schließen'
                  value={datepicker}
                  setValue={setDatepicker}
                />
              </MDBCol>
              <MDBCol lg='9'>
                <MDBInputGroup>
                  <MDBInputGroupElement
                    placeholder='Suchbegriff wie z.B. "Zirbitz"'
                    type='text'
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <MDBBtn onClick={() => searchSpot()}>Suchen</MDBBtn>
                </MDBInputGroup>
              </MDBCol>
            </MDBRow>
          </MDBCardText>
          <MDBCardFooter>
            Wählen Sie ein Datum und geben einen Suchbegriff ein
          </MDBCardFooter>
        </MDBCardBody>
      </MDBCard>
      {data && data.success && data.data.length === 0 && (
        <div>keine Touren gefunden</div>
      )}
      {data &&
        data.success &&
        data.data.length > 0 &&
        data.success === true && (
          <TourResult data={data.data} datum={datepicker} />
        )}
    </>
  );
};

export default MainForm;
