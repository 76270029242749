import TourResultDetails from './TourResultDetails';
import { MDBListGroup } from 'mdb-react-ui-kit';

const TourResult = (props) => {
  const List = props.data.map((entry) => (
    <TourResultDetails key={entry.id} info={entry} datum={props.datum} />
  ));
  return (
    <>
      <MDBListGroup>{List}</MDBListGroup>
    </>
  );
};

export default TourResult;
