import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCheckbox,
  MDBBtn,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBToast,
} from 'mdb-react-ui-kit';

const LoginRegister = () => {
  const [activeTab, setActiveTab] = useState('loginTab');
  // Fehlermeldung
  const [errMessage, setErrMessage] = useState('');
  // Login-Formular
  const [email1, setEmail1] = useState('');
  const [password1, setPassword1] = useState('');

  // Register-Formular
  const [userName, setUserName] = useState('');
  const [email2, setEmail2] = useState('');
  const [password2, setPassword2] = useState('');
  const [password3, setPassword3] = useState('');

  // Daten nach Login/Register
  const [loginData, setLoginData] = useState(null);

  // Navigation, um zum Hauptfenster weiterzuleiten
  let navigate = useNavigate();

  // Umschalten zwischen Tabs
  const handleTabClick = (value) => {
    if (value === activeTab) {
      return;
    }
    setActiveTab(value);
  };

  // Login durchführen
  const onLoginClick = () => {
    if (email1.length === 0 || password1.length === 0) {
      setErrMessage('Alle Felder müssen ausgefüllt sein!');
    } else {
      setErrMessage('');
      const url = process.env.REACT_APP_API_URL + 'auth/login';
      axios
        .post(url, { email: email1, password: password1 })
        .then((response) => {
          setLoginData(response.data);
        });
    }
  };

  if (loginData) {
    console.log(loginData);
    if ((loginData.success = true)) {
      localStorage.setItem('token', loginData.token);
      navigate('/');
    }
  }
  // Registrieren
  const onRegisterClick = () => {
    if (
      email2.length === 0 ||
      userName.length === 0 ||
      password2.length === 0 ||
      password3.length === 0
    ) {
      setErrMessage('Alle Felder müssen ausgefüllt sein1!');
    } else if (password2 != password3) {
      setErrMessage('Kennwörter nicht identisch');
    } else {
      setErrMessage('');
      const url = process.env.REACT_APP_API_URL + 'auth/register';
      axios
        .post(url, { nickname: userName, email: email2, password: password2 })
        .then((response) => {
          setLoginData(response.data);
        });
    }
  };

  //  console.log(process.env.REACT_APP_API_URL);
  return (
    <>
      {errMessage.length > 0 && (
        <MDBToast
          color='danger'
          autohide
          position='top-right'
          delay={5000}
          show
          className='mx-auto'
          headerContent={
            <>
              <strong className='me-auto'>SpotMonitor</strong>
              <small>Fehlerhafte Eingabe in Formular</small>
            </>
          }
          bodyContent='Alle Felder müssen ausgefüllt sein!'
        />
      )}
      <div style={{ width: '26rem' }}>
        <MDBTabs pills justify className='mb-3'>
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleTabClick('loginTab')}
              active={activeTab === 'loginTab'}
            >
              Login
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleTabClick('registerTab')}
              active={activeTab === 'registerTab'}
            >
              Registrieren
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>
        <MDBTabsContent>
          <MDBTabsPane show={activeTab === 'loginTab'}>
            <MDBInput
              wrapperClass='mb-4'
              className='mb4'
              type='email'
              id='idemail1'
              label='E-Mail Adresse'
              value={email1}
              onChange={(e) => setEmail1(e.target.value)}
            />
            <MDBInput
              wrapperClass='mb-4'
              className='mb4'
              type='password'
              id='idpassword1'
              label='Kennwort'
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
            />
            <MDBRow className='mb-4'>
              <MDBCol className='d-flex justify-content-center'>
                <MDBCheckbox
                  id='form7Example3'
                  label='Angemeldet bleiben'
                  defaultChecked
                />
              </MDBCol>
              <MDBCol>
                <a href=''>Kennwort vergessen?</a>
              </MDBCol>
            </MDBRow>

            <MDBBtn
              type='submit'
              className='mb-4'
              block
              onClick={() => onLoginClick()}
            >
              Login
            </MDBBtn>

            <div className='text-center'>
              <p>
                Noch nicht dabei?{' '}
                <a href='#!' onClick={() => handleTabClick('registerTab')}>
                  Registrieren
                </a>
              </p>
            </div>
          </MDBTabsPane>
          <MDBTabsPane show={activeTab === 'registerTab'}>
            <MDBInput
              wrapperClass='mb-4'
              className='mb4'
              type='text'
              id='idusername'
              label='Name (für andere sichtbar)'
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <MDBInput
              wrapperClass='mb-4'
              className='mb4'
              type='email'
              id='idemail2'
              label='E-Mail Adresse'
              value={email2}
              onChange={(e) => setEmail2(e.target.value)}
            />
            <MDBInput
              wrapperClass='mb-4'
              className='mb4'
              type='password'
              id='idpassword2'
              label='Kennwort'
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
            <MDBInput
              wrapperClass='mb-4'
              className='mb4'
              type='password'
              id='idpassword3'
              label='Kennwort (Wiederholung)'
              value={password3}
              onChange={(e) => setPassword3(e.target.value)}
            />
            <MDBBtn
              type='submit'
              className='mb-4'
              block
              onClick={() => onRegisterClick()}
            >
              Registrieren
            </MDBBtn>

            <div className='text-center'>
              <p>
                Bereits dabei?{' '}
                <a href='#!' onClick={() => handleTabClick('loginTab')}>
                  Login
                </a>
              </p>
            </div>
          </MDBTabsPane>
        </MDBTabsContent>
      </div>
    </>
  );
};

export default LoginRegister;
