import { MDBListGroupItem, MDBBtn, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

const TourResultDetails = (props) => {
  let progLink = '/info/' + props.info.idTour + '/' + props.datum;
  return (
    <>
      <MDBListGroupItem>
        <MDBRow between>
          <MDBCol lg='10'>
            <b>Tour:</b> {props.info.tourName}, <b>Ausgangspunkt:</b>{' '}
            {props.info.spotName}
          </MDBCol>
          <MDBCol lg='2'>
            {' '}
            <Link to={progLink}>
              <MDBBtn>Info PP</MDBBtn>
            </Link>
          </MDBCol>
        </MDBRow>
      </MDBListGroupItem>
    </>
  );
};

export default TourResultDetails;
