import React from 'react';
import axios from 'axios';
import {
  MDBInputGroup,
  MDBInputGroupText,
  MDBInputGroupElement,
  MDBBtn,
  MDBIcon,
} from 'mdb-react-ui-kit';
import Chat from '../lists/Chat';

const UserChat = (props) => {
  const [chats, setChats] = React.useState(props.estimates);
  const url = process.env.REACT_APP_API_URL + 'infos/chat/' + props.id;
  const token = localStorage.getItem('token');
  const [message, setMessage] = React.useState('');

  React.useEffect(() => {
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setChats(response.data.data);
      });
  }, [props.id]);

  const saveChat = () => {
    const body = { comment: message };
    axios
      .post(url, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setChats(response.data.data);
        setMessage('');
      });
  };

  return (
    <>
      <MDBInputGroup noWrap>
        <MDBInputGroupText>Nachricht:</MDBInputGroupText>
        <MDBInputGroupElement
          placeholder='Beispiel: fahre ab Graz, kann zwei Personen mitnehmen'
          type='text'
          onChange={(e) => setMessage(e.target.value)}
          value={message}
        />
        <MDBBtn onClick={() => saveChat()}>
          <MDBIcon icon='upload' size='fa-2x' />
        </MDBBtn>
      </MDBInputGroup>
      {chats && chats.length > 0 && <Chat data={chats} />}
    </>
  );
};

export default UserChat;
