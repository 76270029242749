import React from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardHeader,
  MDBCardFooter,
  MDBSwitch,
  MDBAccordion,
  MDBAccordionItem,
  MDBRow,
  MDBCol,
  MDBTypography,
} from 'mdb-react-ui-kit';
import Light from '../objects/Light';
import UserEstimate from './UserEstimate';
import ReturnMessage from './ReturnMessage';
import SpotInfo from './Spotinfo';
import UserChat from './UserChat';
import Navbar from './Navbar';
import NextTours from '../lists/NextTours';
import DayJS from 'dayjs';

const InfoPage = () => {
  const [data, setData] = React.useState(null); // Daten
  const [statusMail, setStatusMail] = React.useState(false); // Kenner, ob User Statusmails erhält
  let { id, date } = useParams(); // ID der Tour und Datum
  let dateFormat = DayJS(date, 'YYYY-MM-DD').format('D.M.YYYY');
  const [success, setSuccess] = React.useState(false);

  // URL
  const baseURL =
    process.env.REACT_APP_API_URL + 'infos/ping/' + id + '/' + date;
  // Token
  const token = localStorage.getItem('token');

  // Daten laden
  React.useEffect(() => {
    axios
      .get(baseURL, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setData(response.data.data);
        setStatusMail(response.data.data.reservation);
        window.scrollTo(0, 0);
        if (response.data.success) {
          setSuccess(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, date]);

  // Umschalten, ob User Statusänderungen gemail bekommt
  const handleStatusMailChange = () => {
    let url =
      process.env.REACT_APP_API_URL + 'infos/reservation/' + data.spotDate.id;
    if (!statusMail) {
      // einschalten
      axios
        .post(
          url,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setStatusMail(true);
        });
    } else {
      // ausschalten
      axios
        .delete(url, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setStatusMail(false);
        });
    }
  };

  return (
    <>
      <Navbar />
      <br />
      {success && (
        <>
          <MDBCard>
            <MDBCardHeader>
              <h1>
                {data.parkingSpot.spotName} am {dateFormat}
              </h1>
            </MDBCardHeader>
            <MDBCardBody>
              <MDBCardTitle>Einschätzung gesamt</MDBCardTitle>
              <Light value={data.spotDate.forecast_overall} />
              <br />
              <MDBSwitch
                id='cbStatusMail'
                label='Ich möchte bei Statusänderungen per Mail informiert werden'
                onChange={handleStatusMailChange}
                checked={statusMail}
              />
            </MDBCardBody>
            <MDBCardFooter>Alle Angaben ohne Gewähr!</MDBCardFooter>
          </MDBCard>
          <br />
          <MDBAccordion>
            <MDBAccordionItem
              collapseId='accProgDetails'
              headerTitle='Details Prognose'
            >
              <MDBRow>
                <MDBCol>
                  <Light
                    key={data.tour.id}
                    title='Parkplatz'
                    value={data.spotDate.forecast_spot}
                    small={true}
                    comment='lt. Infos für diesen Parkplatz'
                  />
                </MDBCol>
                <MDBCol>
                  <Light
                    key={data.tour.id}
                    title='PP-Kategorie'
                    value={data.spotDate.forecast_spottype}
                    small={true}
                    comment='lt. Infos für diese Parkplatzkategorie'
                  />
                </MDBCol>
                <MDBCol>
                  <Light
                    key={data.tour.id}
                    title='User-Schätzung'
                    value={data.spotDate.forecast_user}
                    small={true}
                    comment='Einschätzung von Benutzern'
                  />
                </MDBCol>
              </MDBRow>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId='accEstimate'
              headerTitle='Ihre Einschätzung'
            >
              <UserEstimate
                key={data.tour.id}
                date={date}
                estimates={data.estimate}
                id={data.spotDate.id}
              />
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId='accResult'
              headerTitle='Rückmeldung - wie war/ist es heute?'
            >
              <ReturnMessage idSpot={data.parkingSpot.id} key={data.tour.id} />
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId='accInfo'
              headerTitle='Infos über den Ausgangspunkt'
            >
              <SpotInfo
                info={data.parkingSpot}
                comments={data.comments}
                key={data.tour.id}
              />
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId='accChat'
              headerTitle='Fahrgemeinschaften'
            >
              <div>
                Sie sind für den {dateFormat} an einer Fahrgemeinschaft zu
                diesen Parkplatz interessiert?
              </div>
              <UserChat id={data.spotDate.id} key={data.tour.id} />
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId='accNearby'
              headerTitle='Touren in der Nähe'
            >
              <NextTours
                idSpot={data.parkingSpot.id}
                date={date}
                key={data.tour.id}
              />
            </MDBAccordionItem>
          </MDBAccordion>
        </>
      )}
    </>
  );
};

export default InfoPage;
