import { MDBListGroupItem } from 'mdb-react-ui-kit';
import DayJS from 'dayjs';

const CommentDetails = (props) => {
  let datum = DayJS(props.info.updatedAt);

  return (
    <>
      <MDBListGroupItem>
        <div className='d-inline'> {datum.format('YYYY-MM-DD HH:mm')} </div>
        <div className='d-inline'>
          <b>{props.info.nickname}</b>
        </div>
        <div>{props.info.commentText}</div>
      </MDBListGroupItem>
    </>
  );
};

export default CommentDetails;
