import React, { useState } from 'react';
import { Map, Marker, ZoomControl } from 'pigeon-maps';
import { osm } from 'pigeon-maps/providers';

const LocationSelect = ({ pos, posChange, savePos }) => {
  const [center, setCenter] = useState([
    pos[0] ? pos[0] : 47.070914,
    pos[1] ? pos[1] : 15.438217,
  ]);
  const [zoom, setZoom] = useState(11);
  const [lat, setLat] = useState(pos[0] ? pos[0] : 47.070914);
  const [lon, setLon] = useState(pos[1] ? pos[1] : 15.438217);

  const handleClick = (e) => {
    setLat(e.latLng[0]);
    setLon(e.latLng[1]);
    posChange(e.latLng[0], e.latLng[1]);
  };

  return (
    <>
      <Map
        defaultCenter={[lat, lon]}
        defaultZoom={13}
        provider={osm}
        onBoundsChanged={({ center, zoom }) => {
          setCenter(center);
          setZoom(zoom);
        }}
        onClick={(e) => handleClick(e)}
      >
        <ZoomControl />
        <Marker width={50} anchor={[lat, lon]} />
      </Map>
    </>
  );
};

export default LocationSelect;
