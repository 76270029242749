// import { CircleFill } from 'react-bootstrap-icons';
import { MDBIcon } from 'mdb-react-ui-kit';

const Light = (props) => {
  let color = 'dark';
  let message = 'keine Prognose möglich';
  if (props.value === 1) {
    color = 'success';
    message = 'wahrscheinlich genügend freie Plätze';
  }
  if (props.value === 2) {
    color = 'warning';
    message = 'wahrscheinlich wenig freie Plätze';
  }
  if (props.value === 3) {
    color = 'danger';
    message = 'wahrscheinlich voll';
  }
  return (
    <>
      {props.title && <h5>{props.title}</h5>}
      {!props.small && <MDBIcon size='4x' icon='circle' color={color} />}
      {props.small && <MDBIcon size='2x' icon='circle' color={color} />}
      {!props.small && <div>{message}</div>}
      {props.comment && <div>{props.comment}</div>}
    </>
  );
};

export default Light;
