import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { MDBContainer } from 'mdb-react-ui-kit';
import MainForm from './components/forms/MainForm';
import LoginRegister from './components/forms/LoginRegister';
import InfoPage from './components/forms/InfoPage';

function App() {
  return (
    <MDBContainer>
      <Routes>
        <Route exact path='/' element={<MainForm />} />
        <Route path='/info/:id/:date' element={<InfoPage />} />
        <Route path='/login' element={<LoginRegister />} />
      </Routes>
    </MDBContainer>
  );
}

export default App;
