import React from 'react';
import axios from 'axios';
import DayJS from 'dayjs';
import {
  MDBCheckbox,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBToast,
} from 'mdb-react-ui-kit';

const ReturnMessage = (props) => {
  const today = DayJS().format('YYYY-MM-DD');
  const [returnInfo, setReturnInfo] = React.useState('1');
  const [show, setShow] = React.useState(false);
  const [answer, setAnswer] = React.useState('');
  const handleReturnInfo = (e) => {
    setReturnInfo(e.target.value);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSave = () => {
    saveStatus(returnInfo);
  };

  const handleAlarm = () => {
    saveStatus('4');
    handleClose();
  };

  const saveStatus = (statusValue) => {
    const token = localStorage.getItem('token');
    const url =
      process.env.REACT_APP_API_URL +
      'infos/results/' +
      props.idSpot +
      '/' +
      today;
    const body = { result: statusValue };
    axios
      .post(url, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setAnswer('Erledigt');
      });
  };

  return (
    <>
      {answer.length > 0 && (
        <MDBToast
          color='success'
          autohide
          position='top-right'
          delay={5000}
          show
          className='mx-auto'
          headerContent={<strong className='me-auto'>SpotMonitor</strong>}
          bodyContent='Vielen Dank für Ihre Rückmeldung!'
        />
      )}
      <div>Wie waren/sind die Bedingungen heute?</div>
      <br />
      <div>
        <MDBCheckbox
          inline
          label='wenig los'
          type='radio'
          value='1'
          onChange={handleReturnInfo}
          checked={returnInfo === '1'}
        />
        <MDBCheckbox
          inline
          label='viel los'
          type='radio'
          value='2'
          onChange={handleReturnInfo}
          checked={returnInfo === '2'}
        />
        <MDBCheckbox
          inline
          label='praktisch voll'
          type='radio'
          value='3'
          onChange={handleReturnInfo}
          checked={returnInfo === '3'}
        />
        <MDBBtn onClick={handleSave}>Melden</MDBBtn>
      </div>
      <br />
      <MDBBtn color='danger' onClick={handleShow}>
        Überfüllten PP melden
      </MDBBtn>
      <div>
        Anmerkung: wenn Sie melden, dass der Parkplatz überfüllt ist werden alle
        Interessenten dieses Tages verständigt!
      </div>
      <div>Bei Missbrauch wird der Benutzer gesperrt!</div>
      <MDBModal show={show} setShow={setShow} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Parkplatz ist überfüllt</MDBModalTitle>
              <MDBBtn
                className='btn-close'
                color='none'
                onClick={handleClose}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              Sollen die anderen Interessenten verständigt werden?
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='danger' onClick={handleAlarm}>
                Alarm auslösen
              </MDBBtn>
              <MDBBtn onClick={handleClose}>Abbruch</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default ReturnMessage;
