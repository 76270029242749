import React from 'react';
import axios from 'axios';
import {
  MDBSelect,
  MDBInput,
  MDBBtn,
  MDBInputGroup,
  MDBInputGroupElement,
  MDBInputGroupText,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import Comment from '../lists/Comment';
import LocationSelect from './LocationSelect';

const SpotInfo = (props) => {
  const [spotType, setSpotType] = React.useState(props.info.idSpotType);
  const [message, setMessage] = React.useState('');
  const [costs, setCosts] = React.useState(
    props.info.costs ? props.info.costs : ''
  );
  const [comments, setComments] = React.useState(props.comments);
  const [showPosWindow, setShowPosWindow] = React.useState(false);
  const [pos, setPos] = React.useState([props.info.lat, props.info.lon]);
  let mapsLink = 'https://maps.google.com/?q=' + pos[0] + ',' + pos[1];
  const selectData = [
    { text: '1-5 Plätze', value: 1 },
    { text: '6-10 Plätze', value: 2 },
    { text: '11-20 Plätze', value: 3 },
    { text: '21-50 Plätze', value: 4 },
    { text: '51-100 Plätze', value: 5 },
    { text: '101-200 Plätze', value: 6 },
    { text: '201-500 Plätze', value: 7 },
    { text: '>500 Plätze', value: 8 },
  ];
  selectData[spotType - 1].selected = true;

  const saveInfo = () => {
    const url = process.env.REACT_APP_API_URL + 'infos/spot/' + props.info.id;
    const token = localStorage.getItem('token');
    const body = { idSpotType: spotType, costs: costs };
    axios
      .put(url, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data.data);
        setSpotType(response.data.data.idSpotType);
        setCosts(response.data.data.costs ? response.data.data.costs : '');
      });
  };

  const savePos = () => {
    const url = process.env.REACT_APP_API_URL + 'infos/spot/' + props.info.id;
    const token = localStorage.getItem('token');
    const body = { lat: pos[0], lon: pos[1] };
    axios
      .put(url, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        toggleModal();
      });
  };

  const saveMessage = () => {
    const url =
      process.env.REACT_APP_API_URL + 'infos/comment/' + props.info.id;
    const token = localStorage.getItem('token');
    const body = { comment: message };
    axios
      .post(url, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setComments(response.data.data);
        setMessage('');
      });
  };

  const onSelectChange = (e) => {
    if (e) {
      setSpotType(e.value);
    }
  };

  const toggleModal = () => {
    setShowPosWindow(!showPosWindow);
  };

  const posChange = (lat, lon) => {
    setPos([lat, lon]);
  };

  return (
    <div>
      <div>
        Bitte ändern Sie u.a. Angaben, falls diese nicht zutreffend sind.
      </div>
      <MDBBtn className='mb-4' onClick={toggleModal}>
        Lage...
      </MDBBtn>
      {pos[0] != 0 && pos[1] != 0 && (
        <MDBBtn className='mb-4' tag='a' href={mapsLink} target='_blank'>
          Route ...
        </MDBBtn>
      )}
      <MDBSelect
        className='mb-4'
        label='Kapazität'
        data={selectData}
        getValue={(e) => onSelectChange(e)}
      />
      <MDBInput
        className='mb-4'
        type='text'
        label='Kosten'
        placeholder='Beispiel: freiw. Spende'
        onChange={(e) => setCosts(e.target.value)}
        value={costs}
      />
      <MDBBtn className='mb-4' onClick={() => saveInfo()}>
        Speichern
      </MDBBtn>
      <MDBInputGroup noWrap>
        <MDBInputGroupText>Anmerkung:</MDBInputGroupText>
        <MDBInputGroupElement
          placeholder='Beispiel: momentan nicht geräumt'
          type='text'
          onChange={(e) => setMessage(e.target.value)}
        />
        <MDBBtn onClick={() => saveMessage()}>
          <MDBIcon icon='upload' size='fa-2x' />
        </MDBBtn>
      </MDBInputGroup>
      <hr />
      {comments && comments.length > 0 && <Comment data={comments} />}
      <MDBModal show={showPosWindow} setShow={setShowPosWindow}>
        <MDBModalDialog size='fullscreen'>
          <MDBModalContent>
            <MDBModalBody>
              <LocationSelect pos={pos} posChange={posChange} />
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleModal}>
                Abbrechen
              </MDBBtn>
              <MDBBtn onClick={savePos}>Position speichern</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};

export default SpotInfo;
